import {
  ButtonIcon,
  DetailPageExternalLinkSvg,
  DetailPageRecordSvg,
  DetailPageStartOverSvg,
  DetailPageTransactionalSvg,
  Media,
  MediaOverlay,
  PlayerPlaySvg,
} from '@canalplus/dive';
import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';
import { SecondaryAction, SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import classNames from 'classnames/bind';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DiveProgressBar from '../../../../../../components/ProgressBar/DiveProgressBar';
import Sticker from '../../../../../../components/Sticker/Sticker';
import ThumborMediaImage from '../../../../../../components/ThumborMediaImage/ThumborMediaImage';
import { REFRESH_PROGRESS_BAR_MS } from '../../../../../../constants/limits';
import { isOngoing } from '../../../../../../helpers/card/card-helper';
import { getHodorSticker } from '../../../../../../helpers/stickers/stickers-helper';
import I18n from '../../../../../../lang';
import { featUpcomingHodorStickerSelector } from '../../../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../../../store/slices/displayMode-selectors';
import { getPrimaryActionTypes } from '../../../ActionLayout/helpers';

import styles from './EpisodeMedia.css';

const cx = classNames.bind(styles);

export type EpisodeMediaProps = {
  /**
   * Alternative text for the episode media.
   */
  altImage?: string;
  /**
   * URL of the episode media.
   */
  URLImage?: string;
  /**
   * End time of the episode.
   */
  endTime?: number;
  /**
   * Whether the episode is completed.
   */
  isCompleted?: boolean;
  /**
   * Types of primary actions available for the episode.
   */
  primaryActionTypes?: Partial<ReturnType<typeof getPrimaryActionTypes>>;
  /**
   * Personal Video Recorder secondary action.
   */
  pvr?: SecondaryAction;
  /**
   * Start time of the episode.
   */
  startTime?: number;
  /**
   * User progression in the episode.
   */
  userProgress?: number;
  /**
   * Data test id for EpisodeMedia.
   */
  dataTestId?: string;
  /**
   * Data test id for DIVE the media overlay
   */
  dataTestIdMediaOverlay?: string;
  /**
   * Hodor-driven Sticker content
   */
  stickers?: ApiV2SpyroStrateContentSticker[];
};

/**
 * Get the icon to display on the EpisodeMedia overlay
 */
function getOverlayIcon({
  pvrOverlay,
  primaryActionTypes: { isPlay, isTransactional, isDeepLink, isStartOver } = {},
}: {
  pvrOverlay?: SecondaryActionState;
  primaryActionTypes: EpisodeMediaProps['primaryActionTypes'];
}) {
  if (pvrOverlay) return <DetailPageRecordSvg />;
  if (isPlay && !isStartOver) return <PlayerPlaySvg />;
  if (isStartOver) return <DetailPageStartOverSvg />;
  if (isTransactional) return <DetailPageTransactionalSvg />;
  if (isDeepLink) return <DetailPageExternalLinkSvg />;
  return undefined;
}

/**
 * Renders an Episode's image, and optional episode image overlay elements:
 * - Icon (**Play**, **Record**, etc.)
 * - Progress bar
 * - Sticker
 */
export default function EpisodeMedia({
  altImage,
  endTime,
  isCompleted,
  primaryActionTypes,
  pvr,
  startTime,
  URLImage,
  userProgress,
  dataTestId,
  dataTestIdMediaOverlay,
  stickers,
}: EpisodeMediaProps): JSX.Element {
  const isFeatUpcomingHodorSticker = useSelector(featUpcomingHodorStickerSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const { t } = I18n.useTranslation();

  const [isLive, setIsLive] = useState(isOngoing({ startTime, endTime }));

  /**
   * Update the live status of the episode when endTime and startTime change
   */
  const updateEpisodeLiveStatus = useCallback(() => {
    const isOnGoing = isOngoing({ startTime, endTime });
    setIsLive(isOnGoing);
  }, [endTime, startTime]);

  /**
   * Update the live status of the episode every REFRESH_PROGRESS_BAR_MS
   */
  useEffect(() => {
    const updateEpisodeLiveStatusInterval = setInterval(updateEpisodeLiveStatus, REFRESH_PROGRESS_BAR_MS);
    return () => clearInterval(updateEpisodeLiveStatusInterval);
  }, [updateEpisodeLiveStatus]);

  const { isUnavailable, isNotShaded, isPlay, isStartOver, isLiveOnGoing, isLiveShow, isTransactional } =
    primaryActionTypes || {};
  const pvrOverlay = pvr?.states?.[0]?.label
    ? // To display correctly to all users, PVR overlay needs a label
      { ...pvr.states[0] }
    : undefined;
  const overlayIcon = getOverlayIcon({ pvrOverlay, primaryActionTypes });

  const sticker = getHodorSticker(stickers, isFeatUpcomingHodorSticker);

  return (
    <Media
      aspectRatio="169"
      image={<ThumborMediaImage url={URLImage} dimensions={DIMENSIONS.LIST_EPISODES} alt={altImage} />}
      overlay={
        <MediaOverlay
          buttonIcon={
            overlayIcon ? (
              <ButtonIcon
                variant={pvrOverlay?.label ? 'media-overlay-pvr' : 'media-overlay'}
                icon={overlayIcon}
                label={pvrOverlay?.label}
                aria-label={pvrOverlay?.ariaLabel || (isPlay && !isStartOver) ? t('Icon.play') : undefined}
                as="div"
                className={cx('episodeMedia--icon', {
                  'episodeMedia--overlay-icon-conditional': !isTransactional,
                })}
              />
            ) : undefined
          }
          progressBar={
            userProgress || isCompleted ? (
              <DiveProgressBar progress={userProgress} isCompleted={isCompleted} showIconOnComplete isLive={isLive} />
            ) : undefined
          }
          sticker={
            <Sticker
              sticker={sticker}
              variant={
                !!isLiveOnGoing && !userProgress && !isCompleted && (isLiveShow || isLive) && !isTvDevice
                  ? 'live'
                  : undefined
              }
              startTime={startTime}
              endTime={endTime}
            />
          }
          showDarkOverlay={!!pvrOverlay || (isUnavailable && !isNotShaded)}
          data-testid={dataTestIdMediaOverlay}
        />
      }
      className={cx('episodeMedia', {
        'episodeMedia--on-tv': isTvDevice,
      })}
      data-testid={dataTestId}
    />
  );
}
